import React, { useEffect, useState } from "react";
import { bool, object, string } from "prop-types";
import clsx from "clsx";
import labels from "../../../../../config/labels";
import { useStyles } from "./MasterheadDesktop.style";
import MasterheadLogo from "../MasterheadLogo";
import PreviewBanner from "../PreviewBanner/PreviewBanner";
import withConfig from "../../../../../utils/withConfig";
import { useTownTheme } from "../../../../../context/TownThemeContext";
import Grid from "../../../../../common/Grid/Grid";
import Link from "../../../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { useMastheadContext } from "../../../../../context/MastheadContext";
import { Weather } from "../../../../Weather/Weather";
import LoginButton from "./DefaultButtons/LoginButton/LoginButton";
import SearchField from "../../../../../common/SearchField/SearchField";
import { handleSearch } from "../../../../../utils/helper";
import NewsletterSignupButton from "./DefaultButtons/NewsletterSignupButton/NewsletterSignupButton";
import SquareFacebookBlack from "../../../../../common/Icons/Social/SquareFacebookBlack";
import SquareTwitterBlack from "../../../../../common/Icons/Social/SquareTwitterBlack";
import SquareInstagramBlack from "../../../../../common/Icons/Social/SquareInstagramBlack";
import SquareYoutubeBlack from "../../../../../common/Icons/Social/SquareYoutubeBlack";
import SquareLinkedinBlack from "../../../../../common/Icons/Social/SquareLinkedinBlack";
import SquareBlueskyBlack from "../../../../../common/Icons/Social/SquareBlueskyBlack";
import SquareTiktokBlack from "../../../../../common/Icons/Social/SquareTiktokBlack";
import SquareRssFeedBlack from "../../../../../common/Icons/Social/SquareRssFeedBlack";
import SavedDraftsModal from "../../../../Modals/SavedDraftsModal/SavedDraftsModal";
import SubmitContentButton from "./DefaultButtons/SubmitContentButton/SubmitContentButton";
import SubmitContentModal from "../../../../Modals/SubmitContentModal/SubmitContentModal";

const GRAY = "#9C9C9C";
const DARKGRAY = "#191919";

const MasterheadDesktop = ({
  isArticlePreview,
  legalNoticePermissions,
  isPathUserSignIn,
  townSlug,
  section,
  currentUser,
}) => {
  const [openSubmitContentPopUp, setOpenSubmitContentPopUp] = useState(false);
  const [openSavedDraftsPopUp, setOpenSavedDraftsPopUp] = useState(false);
  const [submitContentButton, setSubmitContentButton] = useState();
  const [newsletterSignupButton, setNewsletterSignupButton] = useState();
  const [loginButton, setLoginButton] = useState();
  const [searchText, setSearchText] = useState("");
  const classes = useStyles();
  const { town, signUpPath, firstBlockButtons } = useTownTheme();
  const { mastheadInfo, adminMenuItems, townsWithClusters, displayWeather } =
    useMastheadContext();
  const adminLinkLevel = screenWidthIsLowerThan(values.lg)
    ? "t4_text_4"
    : "paragraph_1";


  const handleSearchOnEnter = (key) => {
    if (key === "Enter" || key === "13") {
      handleSearch(town, searchText);
    }
  };

  useEffect(() => {
    const newsletterButton = firstBlockButtons.find((button) =>
      ["signup", "subscription", "subscribe"].some((keyword) =>
        button.action.includes(keyword)
      )
    );
    if (newsletterButton) {
      setNewsletterSignupButton(newsletterButton);
    }
    const submitButton = firstBlockButtons.find((button) =>
      button.action.includes("submit")
    );
    if (submitButton) {
      setSubmitContentButton(submitButton);
    }

    const login = firstBlockButtons.find((button) =>
      button.action.includes("login")
    );
    if (login) {
      setLoginButton(login);
    }

    return () => setNewsletterSignupButton();
  }, [firstBlockButtons]);

  return (
    <>
      {isArticlePreview && <PreviewBanner />}

      <Grid
        style={{
          backgroundColor: "white",
        }}
        className={clsx(
          classes.masterhead_desktop_container,
          classes.no_printing,
          classes.licenses_menu_container
        )}
        justifyContent="space-evenly"
        container
        xs={12}
        item
      >
        <Grid xs={12} container item justifyContent="space-between">
          {displayWeather && (
            <Grid xs={3} item>
              <Weather
                townSlug={town?.slug}
                sectionSlug={section?.accu_weather_name ? section?.slug : null}
                clickable={true}
                labelColorWeather={DARKGRAY}
              />
            </Grid>
          )}
          <Grid
            xs={displayWeather ? 8 : 12}
            container
            item
            justifyContent="flex-end"
            className={classes.right_buttons_container}
          >
            {(town?.facebook_url ||
              town?.instagram_url ||
              town?.twitter_url ||
              town?.youtube_url ||
              town?.linkedin_url ||
              town?.bluesky_url ||
              town?.tiktok_url ||
              town?.rss_url) && (
              <>
                <div
                  className={classes.social_media_container}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {town?.facebook_url && (
                    <Link
                      url={town?.facebook_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareFacebookBlack />
                    </Link>
                  )}
                  {town?.twitter_url && (
                    <Link
                      url={town?.twitter_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareTwitterBlack />
                    </Link>
                  )}
                  {town?.instagram_url && (
                    <Link
                      url={town?.instagram_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareInstagramBlack />
                    </Link>
                  )}
                  {town?.youtube_url && (
                    <Link
                      url={town?.youtube_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareYoutubeBlack />
                    </Link>
                  )}
                  {town?.linkedin_url && (
                    <Link
                      url={town?.linkedin_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareLinkedinBlack />
                    </Link>
                  )}
                  {town?.bluesky_url && (
                    <Link
                      url={town?.bluesky_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareBlueskyBlack />
                    </Link>
                  )}
                  {town?.tiktok_url && (
                    <Link
                      url={town?.tiktok_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareTiktokBlack />
                    </Link>
                  )}
                  {town?.rss_url && (
                    <Link
                      url={town?.rss_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareRssFeedBlack />
                    </Link>
                  )}
                </div>
                <div
                  style={{ color: GRAY, display: "flex", alignItems: "center" }}
                >
                  |
                </div>
              </>
            )}
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 16,
              }}
            >
              {adminMenuItems?.map((item, index) => (
                <Grid
                  key={`admin-menu-item-${index}`}
                  item
                  className={classes.admin_link}
                >
                  <Link
                    {...(item.code != "drafts" && { url: item.path })}
                    {...(item.code === "drafts" && {
                      onClick: () => setOpenSavedDraftsPopUp(true),
                    })}
                    children={item.label}
                    color="black"
                    level={adminLinkLevel}
                    underlined
                  />
                </Grid>
              ))}
              {submitContentButton && (
                <Link
                  className={classes.admin_link}
                  children={labels.SUBMIT_CONTENT}
                  color="black"
                  level={adminLinkLevel}
                  underlined
                  onClick={() => setOpenSubmitContentPopUp(true)}
                />
              )}
              {currentUser === null && (
                <div className={classes.logged_container}>
                  <LoginButton
                    button={loginButton}
                    townsWithClusters={townsWithClusters}
                    className={classes.custom_button_login}
                  />
                </div>
              )}
              {currentUser !== null && currentUser?.email && (
                <div className={classes.logged_container}>
                  <Link
                    url={withConfig("SIGN_OUT")}
                    children={labels.LOGOUT}
                    color="black"
                    level={adminLinkLevel}
                    underlined
                  />
                </div>
              )}
            </Grid>
            {newsletterSignupButton && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <NewsletterSignupButton
                  button={newsletterSignupButton}
                  signUpPath={signUpPath}
                  className={classes.signup}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid xs={12} container item>
          <Grid
            item
            className={clsx(classes.grid_item, classes.logo_container)}
          >
            <MasterheadLogo
              envUrl={withConfig("HOME")}
              townName={town?.name || section?.name}
              logoUrl={mastheadInfo?.logo_image}
              townSlug={town?.slug}
              sectionSlug={section?.slug}
              isLicense={town?.under_license_contract}
              color={"black"}
              displayLogo={"flex-start"}
              displayLabelSlogan={"left"}
            />
            <SearchField
              placeholder={labels.SEARCH}
              onChange={setSearchText}
              startIcon
              startIconFill={GRAY}
              onKeyPress={handleSearchOnEnter}
              classes={{
                text_field: classes.search_text_field,
                start_icon: classes.textfield_search_icon,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <SavedDraftsModal
        handleClose={() => setOpenSavedDraftsPopUp(false)}
        openState={openSavedDraftsPopUp}
        currentUserId={currentUser?.id}
      />
      <SubmitContentModal
        town={{ id: townSlug }}
        currentUserId={currentUser?.email}
        legalNoticePermissions={legalNoticePermissions}
        handleClose={() => setOpenSubmitContentPopUp(false)}
        openState={openSubmitContentPopUp}
      />
    </>
  );
};

MasterheadDesktop.propTypes = {
  isArticlePreview: bool,
  legalNoticePermissions: object,
  isPathUserSignIn: bool,
  townSlug: string,
  section: object,
  currentUser: object,
};

export default MasterheadDesktop;
