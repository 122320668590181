import { color } from "@uiw/react-color";
import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;

  return {
    button_icon: {
      padding: "0px !important",
    },
    icon: {
      width: spacing(4),
      height: spacing(4),
    },
    search_text_field: {
      borderRadius: "4px",
      width: "50% !important",
      height: spacing(4),
      "& input.MuiOutlinedInput-input": {
        fontSize: "12px !important",
      },
    },
    textfield_search_icon: {
      width: spacing(1.5),
      height: spacing(1.5),
    },
    grid_item: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: spacing(1),
      position: "relative",
      margin: `${spacing(1.6)}px 0px !important`,
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
    top_nav_bar: {
      backgroundColor: "#E9E9E9",
      flexDirection: "row",
      "&.MuiGrid-spacing-xs-2": {
        margin: 0,
        width: "100%",
      },
      [below(values.md)]: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: spacing(1),
        alignItems: "center",
        zIndex: 1300,
      },
      padding: `${spacing(2)}px ${spacing(8)}px`,
      "& div > .MuiIconButton-root": {
        marginLeft: `0px !important`,
      },
    },
    top_nav_bar_section: {
      overflowY: "scroll",
      maxHeight: `${spacing(65.625)}px`,
      borderTop: `0.5px solid #CECECE`,
      backgroundColor: "#E9E9E9",
      flexDirection: "row",
      "&.MuiGrid-spacing-xs-2": {
        margin: 0,
        width: "100%",
      },
      [below(values.md)]: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: spacing(1),
        alignItems: "center",
        zIndex: 1300,
      },
      padding: `${spacing(2)}px ${spacing(8)}px`,
      "& div > .MuiIconButton-root": {
        marginLeft: `0px !important`,
      },
    },
    no_padding: {
      padding: "8px 0px",
    },
    reduce_font_size: {
      [below(1032)]: {
        fontSize: "12px !important",
      },
    },
    signup: {
      width: "fit-content !important",
      margin: "0px !important",
      height: "fit-content !important",
      textTransform: "uppercase !important",
      padding: `${spacing(0.5)}px ${spacing(2)}px !important`,
      "& .MuiButton-label": {
        fontWeight: "500 !important",
        fontSize: "12px !important",
      },
    },
    icons_container: {
      display: "flex",
      alignItems: "center",
      gap: spacing(1),
    },
    list_container: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      gap: spacing(6),
      margin: "0px !important",
      overflowY: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      paddingRight: `${spacing(2)}px`,
      "& li > a": {
        textWrap: "nowrap",
      },
    },
    lists_sections: {
      display: "flex",
      flexDirection: "row",
      gap: spacing(7),
    },
    list_container_submenu: {
      display: "flex",
      flexWrap: "wrap",
      gap: spacing(7),
      margin: "0px !important",
      alignContent: "flex-start",
    },
    list_container_submenu_with_subections: {
      flex: "1 1 0%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(150px, max-content))",
      gridTemplateRows: "repeat(auto-fit, minmax(min-content, 0px))",
      gap: spacing(6),
      margin: "0px !important",
    },
    list_container_subsection: {
      display: "flex",
      flexDirection: "column",
      gap: spacing(2),
      margin: "0px !important",
      "& a": {
        color: "#191919 !important",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    menu_drop_down_item: {
      textWrap: "nowrap",
      display: "flex",
      alignItems: "center",
      color: "black",
      "& a": {
        color: "black",
        "&:hover": {
          fontWeight: 600,
          textDecoration: "underline",
          textDecorationThickness: "2px",
        }
      },
      cursor: "pointer",
    },
    menu_item: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& a": {
        width: "100%",
        color: "black",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: `${spacing(1)}px`,
        "&:hover": {
          textDecoration: "underline",
          fontWeight: "600",
          textDecorationThickness: "2px",
        }
      },
    },
    menu_item_section: {
      display: "flex",
      flexDirection: "column",
      "& a": {
        color: "black",
        fontWeight: 400,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      gap: spacing(2),
      "& ul": {
        marginLeft: `${spacing(1)}px !important`,
        paddingLeft: `${spacing(1)}px !important`,
        borderLeft: `0.5px solid #CECECE`,
        fontStyle: "italic",
        flexWrap: "wrap",
        "& a": {
          fontWeight: "normal",
        },
      },
    },
    menu_list_choose: {
      fontWeight: "bold",
      textDecoration: "underline",
    },
    container_shadow: {
      width: "100%",
      height: "100%",
      position: "absolute",
      background:
        "linear-gradient(90deg, rgba(0,0,0,0) 85%, rgb(233, 233, 233, 1) 100%)",
      pointerEvents: "none",
    },
    leftArrowButton: {
      position: "absolute",
      top: 0,
      "&:hover": {
        color: `${theme.palette.themeColor.primary} !important`
      }
    },
    rightArrowButton: {
      position: "absolute",
      top: 0,
      "&:hover": {
        color: `${theme.palette.themeColor.primary} !important`
      }
    }
  };
});

export { useStyles };
